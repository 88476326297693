@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,700;1,400&family=Source+Serif+Pro&display=swap');
@import 'react-datepicker/dist/react-datepicker';

body, .font-poppins {
    font-family: Poppins, sans-serif;
}

@layer base {
    .text-anchor-middle {
        text-anchor: middle;
    }

    iframe#webpack-dev-server-client-overlay {
        display: none;
      }
}

@layer utilities {
    /* scrollbar */
    .scrollbar {
        &::-webkit-scrollbar {
            @apply w-4;
        }
        &::-webkit-scrollbar-track {
            @apply bg-brand_grey-light border border-brand_grey border-solid;
        }
        &::-webkit-scrollbar-thumb {
            @apply bg-brand_secondary-light border-y-[4px] border-x-[6px] border-solid border-transparent bg-clip-content;
            &:hover {
                @apply bg-brand_secondary;
            }            
        }
        &-sm {
            &::-webkit-scrollbar {
                @apply w-3 h-3.5;
            }
        }
        &-full {
            &::-webkit-scrollbar-track {
                @apply border-x-0;
            }
        }
        &-breadcrumbs, &-media {
            &::-webkit-scrollbar-track {
                @apply border-b-0;
            }
        }
        &-select {
            &::-webkit-scrollbar-track {
                @apply border-y-0 rounded-br-md;
            }
            &::-webkit-scrollbar-thumb {
                @apply border-x-4;
            }
        }
        &-modal {
            &::-webkit-scrollbar-track {
                @apply border-y-0;
            }
            &::-webkit-scrollbar-thumb {
                @apply border-x-4;
            }
        }
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }
    /* links & buttons */
    .link {
        @apply text-brand_tertiary cursor-pointer;
        &:hover {
            @apply text-brand_tertiary-dark;
        }
        & .loading-dots {
            @apply bg-transparent ring-0 shadow-none w-auto h-5 relative;
            &>span {
                &>div {
                    @apply bg-brand_grey-medium;
                }
            }
        }
        &-small {
            @apply text-sm;
        }
        &-primary {
            @apply text-brand_primary;
            &:hover {
                @apply text-brand_primary-dark;
            }
        }
        &-secondary {
            @apply text-brand_secondary;
            &:hover {
                @apply text-brand_secondary-dark;
            }
        }
        &-tertiary, &-info {
            @apply text-brand_tertiary;
            &:hover {
                @apply text-brand_tertiary-dark;
            }
        }
        &-grey {
            @apply text-brand_grey-medium;
            &:hover {
                @apply text-brand_grey-dark;
            }
        }
        &-success {
            @apply text-brand_status-success;
            &:hover {
                @apply text-brand_status-success;
            }
        }
        &-error {
            @apply text-brand_status-error;
            &:hover {
                @apply text-brand_status-error;
            }
        }
        &-warning {
            @apply text-brand_status-warning;
            &:hover {
                @apply text-brand_status-warning;
            }
        }
    }
    .btn {
        @apply font-bold min-h-10 flex items-center justify-center px-6 rounded-full bg-brand_primary text-white no-underline whitespace-nowrap w-fit leading-none cursor-pointer text-md lg:text-base;
        &>span {
            @apply flex items-center;
        }
        &:disabled {
            @apply opacity-50 cursor-default hover:bg-brand_primary;
        }
        &:hover {
            @apply bg-brand_primary-dark;
        }
        & .loading-dots {
            @apply bg-transparent ring-0 shadow-none h-5 relative;
            &>span {
                &>div {
                    @apply bg-white;
                }
            }
        }
        &-small {
            @apply font-normal py-1 px-2 rounded-md;
        }
        &-secondary {
            @apply bg-brand_secondary;
            &:disabled {
                @apply hover:bg-brand_secondary;
            }
            &:hover {
                @apply bg-brand_secondary-dark;
            }
        }
        &-tertiary, &-info {
            @apply bg-brand_tertiary;
            &:disabled {
                @apply hover:bg-brand_tertiary;
            }
            &:hover {
                @apply bg-brand_tertiary-dark;
            }
        }
        &-grey {
            @apply bg-brand_grey-medium;
            &:disabled {
                @apply hover:bg-brand_grey-medium;
            }
            &:hover {
                @apply bg-brand_grey-dark;
            }
        }
        &-success {
            @apply bg-brand_status-success;
            &:disabled {
                @apply hover:bg-brand_status-success;
            }
            &:hover {
                @apply bg-brand_status-success;
            }
        }
        &-error {
            @apply bg-brand_status-error;
            &:disabled {
                @apply hover:bg-brand_status-error;
            }
            &:hover {
                @apply bg-brand_status-error;
            }
        }
        &-warning {
            @apply bg-brand_status-warning;
            &:disabled {
                @apply hover:bg-brand_status-warning;
            }
            &:hover {
                @apply bg-brand_status-warning;
            }
        }
        &-outline {
            @apply bg-white text-brand_primary ring-1 ring-inset ring-brand_primary;
            &:hover {
                @apply bg-brand_primary-dark ring-brand_primary-dark text-white;
                & .loading-dots {
                    &>span {
                        &>div {
                            @apply bg-white;
                        }
                    }
                }
            }
            &.active {
                @apply bg-brand_primary text-white;
            }
            & .loading-dots {
                &>span {
                    &>div {
                        @apply bg-brand_grey-medium;
                    }
                }
            }
            &-secondary {
                @apply  text-brand_secondary ring-brand_secondary;
                &:hover {
                    @apply bg-brand_secondary-dark ring-brand_secondary-dark text-white;
                }
                &.active {
                    @apply bg-brand_secondary text-white;
                }
            }
            &-tertiary, &-info {
                @apply text-brand_tertiary ring-brand_tertiary;
                &:hover {
                    @apply bg-brand_tertiary-dark ring-brand_tertiary-dark text-white;
                }
                &.active {
                    @apply bg-brand_tertiary text-white;
                }
            }
            &-grey {
                @apply text-brand_grey-medium ring-brand_grey-medium;
                &:hover {
                    @apply bg-brand_grey-dark ring-brand_grey-dark text-white;
                }
            }
            &-success {
                @apply text-brand_status-success ring-brand_status-success;
                &:hover {
                    @apply bg-brand_status-success ring-brand_status-success text-white;
                }
            }
            &-error {
                @apply text-brand_status-error ring-brand_status-error;
                &:hover {
                    @apply bg-brand_status-error ring-brand_status-error text-white;
                }
            }
            &-warning {
                @apply text-brand_status-warning ring-brand_status-warning;
                &:hover {
                    @apply bg-brand_status-warning ring-brand_status-warning text-white;
                }
            }
        } 
    }

    /* forms */
    input, textarea, select {
        @apply w-full leading-none box-border border border-brand_grey rounded-md lg:rounded-lg px-3 py-2 placeholder:text-brand_form-placeholder focus:outline-none text-sm md:text-md focus:border-brand_form-borderFocus;
    }
    .form {
        &-component {
            @apply flex flex-col relative;
            &>label {
               @apply mb-0.5 font-semibold text-sm lg:text-sm;
            } 
            &>small {
                /* helper text */
               @apply mt-2 text-brand_grey-medium;
            }  
            &>span {
                /* error text */
               @apply absolute top-0 right-0 block text-brand_status-error text-[12px] py-1;
            }
            &.form-inline {
               &>label {
                   @apply absolute top-0 left-0 px-3 pt-2 m-0 z-[9] whitespace-nowrap;
               }
               &>span {
                   @apply p-2;
                }
            }
        }
        &-note {
            @apply text-xs text-brand_grey-medium mt-1 ps-3 md:text-sm;
        }
        &-textbox {
            &.form-error {
               &>input {
                   @apply border-brand_status-error;
               }
           }  
           &.form-inline {
               &>input {
                   @apply pt-8;
               }
           }
           &.form-group {
               @apply flex-row items-center;
               &:has(em:nth-child(1)) {
                   &>input {
                       @apply rounded-s-none;
                   }
               }            
               &:has(em:nth-child(2)), &:has(em:nth-child(3)) {
                   &>input {
                       @apply rounded-e-none;
                   }
               }
               &>input {
                   &+em {
                       @apply rounded-s-none rounded-e-md lg:rounded-e-lg border-e border-s-0;
                   }
               }
               &>em {
                   @apply not-italic leading-none text-xs md:text-sm bg-brand_faint-grey px-1.5 md:px-3 self-stretch flex items-center border-s border-y border-brand_grey text-brand_grey-medium rounded-s-md lg:rounded-s-lg;
               }
           }
        }
        &-autocomplete {
            & input[type="text"] {
                @apply w-full;
                &[aria-expanded="true"] {
                    @apply rounded-b-none ring-brand_form-borderFocus;
                }
            }
            &.form-inline {
                & input[type="text"] {
                    @apply pt-8;
                }
            }
            & .dropdown-menu {
                @apply bg-white z-10 px-3 py-2 text-sm ring-1 ring-inset ring-brand_form-borderFocus -mt-px rounded-b-md lg:rounded-b-lg;
            }
            & .rbt-aux {
                @apply absolute top-0 right-0 text-2xl w-8 h-8 flex justify-center items-center;
            }
        }
        &-textarea {
           &.form-error {
               &>textarea {
                   @apply border-brand_status-error;
               }
           } 
           &.form-inline {
               &>textarea {
                   @apply pt-8;
               }
           }
        }
        &-select { 
            //@apply w-full;
           & .select {
               &__control {
                    @apply z-[8] px-3 py-2 text-sm md:text-md border border-brand_grey bg-white rounded-md lg:rounded-lg min-h-0;
                    &--menu-is-open {
                        @apply border-brand_secondary rounded-b-none;
                    }
               }
               &__menu {
                   @apply -mt-px border text-sm md:text-md bg-white rounded-b-md lg:rounded-b-lg border-brand_secondary;
                   @apply z-30;
                   &-list {
                       @apply overflow-x-hidden scrollbar scrollbar-sm scrollbar-select;
                   }
               }
               &__option {
                   @apply px-3 py-2;
                    &:hover {
                        @apply bg-brand_faint-blue;
                    }
                    &:last-child {
                        @apply rounded-b-md lg:rounded-b-lg;
                    }
                    &--is-selected {
                        @apply bg-brand_faint-green;
                    }
                    &--is-disabled {
                        @apply pointer-events-none py-1;
                        @apply text-brand_grey-dark;
                        & .option-header {
                            @apply font-bold mt-2;
                        }
                        & .option-sub-header {
                            @apply text-sm font-medium;
                        }
                    }
               }
               &__value-container {
                   @apply flex flex-nowrap gap-1 items-center;
               }
               &__multi {
                   &-value {
                       @apply bg-brand_grey-dark text-white text-xs rounded-md py-0.5 px-1.5 inline-flex items-center gap-1 leading-tight;
                   }
               }
           }
           &.form-error {
               &>div {
                   &>div {
                       @apply border-brand_status-error;
                   }
               }
           }  
           &.form-inline {
               & .select {
                   &__control {
                        padding-top: 33px;
                        padding-bottom: 8px;
                        min-height: 65px;
                   }
               }
            }
       }
       &-checkbox {
           &>label {
              @apply relative flex items-center font-normal cursor-pointer text-sm md:text-md leading-4 m-0 whitespace-nowrap;
               &>input {
                   @apply border-none p-0 bg-white shrink-0 me-2 appearance-none h-6 w-6 ring-1 rounded-sm focus:outline-none ring-brand_form-border hover:ring-brand_form-borderFocus checked:bg-brand_secondary checked:ring-brand_secondary cursor-pointer;
                   &:disabled {
                       @apply bg-brand_grey-light ring-brand_form-border hover:ring-brand_form-border;
                       &+svg {
                            @apply text-brand_grey-medium;
                        }
                   }
               }
               &>svg {
                   @apply absolute h-3.5 w-3.5 mx-[0.313rem] text-sm top-[12px] -translate-y-1/2 left-0 hidden text-white pointer-events-none; // top-1/2
               }
           }
           &.form-error {
               &>label {
                   &>input {
                       @apply ring-2 ring-brand_status-error;
                   }  
               }
           }  
      }
      &-radio {
           &>label {
           @apply relative flex items-center font-normal cursor-pointer text-sm md:text-md leading-4 m-0;
               &>input {
                   @apply border-none p-0 bg-white shrink-0 me-2 appearance-none h-5 w-5 ring-1 rounded-full focus:outline-none ring-brand_form-border hover:ring-brand_form-borderFocus checked:bg-brand_secondary checked:ring-brand_secondary cursor-pointer;
                   &:disabled {
                       @apply bg-brand_grey-light hover:ring-brand_form-border;
                   }
               }
               &>svg {
                   @apply absolute h-2.5 w-2.5 mx-[0.313rem] text-sm top-1/2 -translate-y-1/2 left-0 hidden text-white pointer-events-none;
               }
               &>span {
                    @apply relative top-px;
               }
           }
           &.form-error {
               &>label {
                   &>input {
                       @apply ring-2 ring-brand_status-error;
                   }  
               }
           }  
       }
       &-datepicker {
           & input {
               @apply w-full;
           }
           &.form-inline {
               & input {
                   @apply pt-8;
               }
           }
       }
    }
}

/* react select - specific select mods */

#MobileSelectMenu {
    & .select {
        &__single-value {
            @apply text-sm;
        }
        &__option {
            @apply link text-sm;
        }
    }
}

#CommunitySelect {
    & .select {
        &__control {
            @apply border-2 min-h-[60px] py-2;
        }
        &__menu {
            @apply border-s-2 border-e-2 border-b-2;
        }
        &__control, &__menu, &__single-value, &__option, &__group {
            @apply text-sm lg:text-base;
        }
        &__group {
            @apply pt-3 border-t border-brand_grey;
            &-heading {
                @apply py-2 px-3 font-bold;
            }
        }
    }
}

/* LOADING DOTS */

.loading-dots {
    @apply absolute z-20 w-24 bg-white flex items-center justify-center h-12 rounded-[24px] ring-2 ring-brand_secondary shadow-md;
    &>span {
        @apply w-[76px] h-[13px] relative;
        &>div {
            @apply absolute top-0 w-[13px] h-[13px] rounded-full bg-brand_secondary;
            -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
            &:nth-child(1) {
                left: 8px;
                -webkit-animation: loading-dots1 0.6s infinite;
                animation: loading-dots1 0.6s infinite;
            }
            &:nth-child(2) {
                left: 8px;
                -webkit-animation: loading-dots2 0.6s infinite;
                animation: loading-dots2 0.6s infinite;
            }
            &:nth-child(3) {
                left: 32px;
                -webkit-animation: loading-dots2 0.6s infinite;
                animation: loading-dots2 0.6s infinite;
            }
            &:nth-child(4) {
                left: 56px;
                -webkit-animation: loading-dots3 0.6s infinite;
                animation: loading-dots3 0.6s infinite;
            }
        }
    }
}

@-webkit-keyframes loading-dots1 {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes loading-dots1 {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes loading-dots3 {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}

@keyframes loading-dots3 {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}

@-webkit-keyframes loading-dots2 {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(24px, 0);
        transform: translate(24px, 0);
    }
}

@keyframes loading-dots2 {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(24px, 0);
        transform: translate(24px, 0);
    }
}

/* PULSE */
.pulse {
    z-index: 1;
    font-style: normal;
    position: relative;
    &::before,
    &::after {
        border-radius: 100%;
        height: 50px;
        width: 50px;
        opacity: 0;
        content: '';
        margin: auto;
        position: absolute;
        top: calc(50% - 25px);
        left: calc(50% - 25px);
        @media (min-width: 992px) {
            height: 70px;
            width: 70px;
            top: calc(50% - 35px);
            left: calc(50% - 35px);
        }
    }
    &::before {
        background: #fff;
        animation: pulsate2 2.5s ease-in;
        animation-iteration-count: infinite;
        z-index: -1;
    }
    &::after {
        background: #1d8b80;
        animation: pulsate 2.5s ease-out;
        animation-iteration-count: infinite;
        z-index: -2;
    }
}

@keyframes pulsate {
    0% {
        transform: scale(0.01);
    }
    50% {
        opacity: 0;
    }
    75% {
        opacity: 0.375;
    }
    100% {
        transform: scale(1.25);
    }
}

@keyframes pulsate2 {
    0% {
        transform: scale(0.375);
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(1.45);
        opacity: 0;
    }
}

/* DATA TABLES */
.rdt_Table {
    &>.rdt_TableHead {
        &>.rdt_TableHeadRow {
            @apply text-sm lg:text-md font-medium pb-3 min-h-0;
            &>.rdt_TableCol {
                @apply justify-normal px-2 md:px-3 lg:px-4;
                &>.rdt_TableCol_Sortable {
                    @apply items-end leading-tight text-brand_grey-dark opacity-100;
                    &:hover {
                        @apply text-brand_grey-dark;
                    }
                    &>div {
                        /* title */
                        @apply whitespace-normal;
                    }
                    &>span {
                        /* sort arrow */
                        @apply opacity-100;
                        font-size: 1px;
                        letter-spacing: -1px;
                        color: transparent;
                        padding: 2px 6px;
                        &:before {
                            color: #000;
                            display: block;
                            content: '';
                            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8l256 0c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"/></svg>');
                            background-size: 16px 16px;
                            height: 10px;
                            width: 16px;
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
    }
    &>.rdt_TableBody{
        &>.rdt_TableRow {
            @apply text-xs sm:text-sm lg:text-md;
            &>.rdt_TableCell {
                @apply px-2 md:px-3 lg:px-4 py-2 leading-snug justify-start;
            }
            & button[aria-label="Expand Row"], & button[aria-label="Collapse Row"] {
                @apply h-9 w-9 bg-brand_faint-blue rounded-full;
            }
            & button[aria-label="Expand Row"] {
                &:hover {
                    @apply bg-brand_tertiary-light text-white;
                }
                &:focus {
                    @apply bg-brand_faint-blue text-brand_grey-medium;
                }
            }            
            & button[aria-label="Collapse Row"] {
                @apply bg-brand_tertiary-light text-white;
            }
        }
        &>.rdt_ExpanderRow {
            @apply border-b-2 border-brand_grey text-xs sm:text-sm lg:text-md;
        }
    }
}
.rdt_Pagination {
    @apply justify-start text-sm text-brand_grey-medium;
    border: none!important;
    & select {
        @apply ring-1 ring-brand_grey outline-none border-2 border-brand_grey rounded-md ms-2 ps-2 py-0.5 pe-5 w-auto;
    }
    & button {
        @apply w-8 h-8 p-1;
        &:hover:not(:disabled) {
            @apply bg-brand_faint-blue;
        }
    }
    &>span {
        &:nth-of-type(2) {
            @apply ms-auto font-medium;
        }
    }
}

/* highlight the first row */

.rdt_FirstRowHighlight #row-0 {
    @apply bg-brand_faint-blue;
}

/* expand icon text mod */

[data-table="Review Score Breakdown"] {
    .rdt_Table {
        &>.rdt_TableHead {
            &>.rdt_TableHeadRow {
                &>div:nth-child(1) {
                    @apply min-w-14 lg:min-w-20;
                }
            }
        }
        &>.rdt_TableBody{
            &>.rdt_TableRow {
                &>div:nth-child(1) {
                    @apply min-w-14 lg:min-w-20;
                }
                & button[aria-label="Expand Row"]:disabled, & button[aria-label="Collapse Row"]:disabled {
                    @apply hidden;
                }
                & button[aria-label="Expand Row"], & button[aria-label="Collapse Row"] {
                    @apply bg-brand_primary w-14 h-auto rounded-md p-1 lg:w-20 lg:p-2 text-2xs lg:text-xs whitespace-normal leading-none text-white;
                }
                & button[aria-label="Expand Row"] {
                    &:hover {
                        @apply bg-brand_primary-dark text-white;
                    }
                    &:focus {
                        @apply bg-brand_primary;
                    }
                }            
                & button[aria-label="Collapse Row"] {
                    @apply bg-brand_primary-dark;
                }
            }
        }
    }
}

/* responsive/visible columns per table */

/* leads */

[data-table="Leads Overview"] {
    & .rdt_TableHeadRow>[data-column-id], & .rdt_TableRow>[data-column-id] {
        &:nth-child(1) {
           
        }
    }
}
[data-table="Lead Breakdown by Facility"] {
    & .rdt_TableHeadRow>[data-column-id], & .rdt_TableRow>[data-column-id] {
        &:nth-child(1) {
            @apply min-w-60 md:min-w-80 max-w-96;
        }
        &:nth-child(n+2) {
            max-width: 130px;
            @media (min-width: 768px) {
                max-width: 160px;
            }
            @media (min-width: 1280px) {
                max-width: 180px;
            }
        }
    }
}

[data-table="Email Inquiry & Brochure Inquiry Log"] {
    & .rdt_TableHeadRow>[data-column-id], & .rdt_TableRow>[data-column-id] {
        &:nth-child(1) {
        
        }
    }
}

/* profile content */

[data-table="Content Overview"] {
    & .rdt_TableHeadRow>[data-column-id], & .rdt_TableRow>[data-column-id] {
        &>div>div {
            @apply overflow-visible;
        }
        &:nth-child(1) {
            @apply min-w-48 md:max-w-80 xl:sticky xl:left-0 xl:bg-white xl:z-10;
        }
        &:nth-child(2) {
            @apply min-w-44 max-w-44;
        }
    }
}

[data-table="Care Offered"] {
    & .rdt_TableHeadRow>[data-column-id], & .rdt_TableRow>[data-column-id] {
        &:nth-child(1) {
            @apply min-w-48 md:max-w-80;
        }
        &:nth-child(2) {
            @apply min-w-44 lg:min-w-48 max-w-48;
        }
        &:nth-child(3) {
            @apply min-w-80;
        }
        &:nth-child(4) {
            @apply min-w-36 max-w-56;
        }
        &:nth-child(5) {
            @apply min-w-36 max-w-56;
        }
        &:nth-child(6) {
            @apply min-w-28 max-w-28;
        }
    }
}

[data-table="Services & Amenities"] {
    & .rdt_TableHeadRow>[data-column-id], & .rdt_TableRow>[data-column-id] {
        &:nth-child(1) {
            @apply min-w-60;
        }
        &:nth-child(2) {
            @apply min-w-48 max-w-48;
        }
        &:nth-child(3) {
            @apply min-w-32 max-w-32;
        }
        &:nth-child(4) {
            @apply min-w-32 max-w-32;
        }
        &:nth-child(5) {
            @apply min-w-36 max-w-36;
        }
        &:nth-child(6) {
            @apply min-w-40 max-w-40;
        }
        &:nth-child(7) {
            @apply min-w-32 max-w-32;
        }
        &:nth-child(8) {
            @apply min-w-36 max-w-36;
        }
        &:nth-child(9) {
            @apply min-w-36 max-w-36;
        }
        &:nth-child(10) {
            @apply min-w-24 max-w-24;
        }
    }
}

[data-table="Cost / Funding"] {
    & .rdt_TableHeadRow>[data-column-id], & .rdt_TableRow>[data-column-id] {
        &:nth-child(1) {
            @apply min-w-72;
        }
        &:nth-child(2) {
            @apply min-w-52 max-w-52;
        }
        &:nth-child(7) {
            @apply min-w-24 max-w-24;
        }
    }
}

[data-table="PDF Brochures"] {
    & .rdt_TableHeadRow>[data-column-id], & .rdt_TableRow>[data-column-id] {
        &:nth-child(1) {
            @apply min-w-60 md:min-w-80 max-w-96;
        }
        &:nth-child(2) {
            @apply min-w-48 max-w-48;
        }
        &:nth-child(3) {

        }
        &:nth-child(4) {
            @apply max-w-64;
        }
    }
}


[data-table="Tour Availability"] {
    & .rdt_TableHeadRow>[data-column-id], & .rdt_TableRow>[data-column-id] {
        &:nth-child(2) {
            @apply min-w-60 max-w-60;
        }
        &:nth-child(3) {
            @apply min-w-48 max-w-48;
        }
    }
}

[data-table="Logos"] {
    & .rdt_TableHeadRow>[data-column-id], & .rdt_TableRow>[data-column-id] {
        &:nth-child(1) {
            @apply min-w-60 md:min-w-80 max-w-96;
        }
        &:nth-child(2) {
            @apply min-w-72;
        }
        &:nth-child(3) {
            @apply min-w-72;
        }
        &:nth-child(4) {
            @apply max-w-44;
        }
    }
}

/* contact details & portal users */

[data-table="Inquiry Phone / Email / Website"] {
    & .rdt_TableHeadRow>[data-column-id], & .rdt_TableRow>[data-column-id] {
        &:nth-child(1) {
            
        }
    }
}

[data-table="Portal Users"] {
    & .rdt_TableHeadRow>[data-column-id], & .rdt_TableRow>[data-column-id] {
        &:nth-child(1) {
            
        }
    }
}

/* reviews */

[data-table="Pending Publication Reviews"] {
    & .rdt_TableHeadRow>[data-column-id], & .rdt_TableRow>[data-column-id] {
        &:nth-child(1) {
            /* expand column */
        }
        &:nth-child(2) {
            max-width: 120px;
            min-width: 120px;
            @media (min-width: 768px) {
                max-width: 140px;
                min-width: 140px;                
            }
        }
        &:nth-child(3) {
            @apply min-w-[200px] md:min-w-min;
        }
        &:nth-child(4) {
            @apply hidden md:flex;
        }
        &:nth-child(5) {
            @apply hidden lg:flex;
        }
        &:nth-child(6) {
            min-width: 190px;
        }
        &:nth-child(7) {
            @apply hidden xl:flex;
        }
        &:nth-child(8) {
            @apply min-w-[280px] hidden xl:flex flex-col gap-2;
        }
    }
}

[data-table="Published Reviews"] {
    & .rdt_TableHeadRow>[data-column-id], & .rdt_TableRow>[data-column-id] {
        &:nth-child(1) {
            /* expand column */
        }
        &:nth-child(2) {
            max-width: 120px;
            min-width: 120px;
            @media (min-width: 768px) {
                max-width: 140px;
                min-width: 140px;                
            }
        }
        &:nth-child(3) {
            
        }
        &:nth-child(4) {
            @apply hidden sm:flex;
        }
        &:nth-child(5) {
            @apply hidden md:flex;
        }
        &:nth-child(6) {
            @apply hidden lg:flex;
        }
        &:nth-child(7) {
            @apply xl:min-w-[200px] hidden lg:flex;
        }
        &:nth-child(8) {
            @apply max-w-[180px] hidden xl:flex;
        }
    }
}

[data-table="Review Score Breakdown"] {
    & .rdt_TableHeadRow>[data-column-id], & .rdt_TableRow>[data-column-id] {
        &:nth-child(1) {
            /* expand column */
        }
        &:nth-child(2) {
            @media (min-width: 768px) {
                min-width: 240px;                
            }
        }
        &:nth-child(3) {
            max-width: 100px;
            min-width: 100px;
            @media (min-width: 768px) {
                max-width: 120px;
                min-width: 120px;                
            }

        }
        &:nth-child(n+4) {
            @apply hidden md:flex;
        }
    }
}

[data-table="Review Cards"] {
    & .rdt_TableHeadRow>[data-column-id], & .rdt_TableRow>[data-column-id] {
        &:nth-child(1) {
            max-width: 260px;
        }
        &:nth-child(2) {
           max-width: 200px;
        }
        &:nth-child(3) {
            
        }
        &:nth-child(4) {
            max-width: 200px;
        }
        &:nth-child(5) {
            max-width: 200px;
        }
        &:nth-child(6) {
            max-width: 100px;
            min-width: 100px;
        }
        &:nth-child(7) {
            max-width: 100px;
            min-width: 100px;
        }
        &:nth-child(8) {
            max-width: 130px;
        }
    }
}

[data-table="Review Cards Mobile"] {
    & .rdt_TableHeadRow>[data-column-id], & .rdt_TableRow>[data-column-id] {
        &:nth-child(1) {
            
        }
    }
}

/* MODAL */


/* PERMISSIONS */
.permission {
    &-reviews {
        background-color: #f40009;
    }
    &-notifications {
        background-color: #8b0000;
    }
    &-users {
        background-color: #00308f;
    }
    &-reviewcardprimary {
        background-color: #de3163;
    }
    &-primarysubscriptioncontact {
        background-color: #3fb474;
    }
    &-primarycontact {
        background-color: #0a5f15;
    }
}

/* REACT DATEPICKER */
.react-datepicker {
    font-family: Poppins, sans-serif;
    &-popper {
        @apply z-50;
    }
    &__day {
        @apply rounded-full;
        &--keyboard-selected,
        &--selected {
            @apply text-white bg-brand_secondary;
        }
        &:hover {
            @apply bg-brand_faint-blue;
        }
    }
}


.Toastify {
    &__toast-container {
        @apply [@media(min-width:481px)]:w-72;
        @apply lg:w-80 xl:w-96;
    }
    &__toast {
        @apply bg-brand_grey-dark text-white rounded-b-md [@media(min-width:481px)]:rounded-md p-3 sm:p-4 2xl:p-5 shadow-lg;
        &--info {
            @apply bg-brand_status-info;
        }
        &--success {
            @apply bg-brand_status-success;
        }
        &--error {
            @apply bg-brand_status-error;
        }
        &--warning {
            @apply text-brand_grey-dark bg-brand_status-warning;
        }
        &-body {
            font-family: Poppins, sans-serif;
            @apply flex items-start leading-tight p-0 pe-2 font-medium text-sm lg:text-md;
            &>div:not(.Toastify__toast-icon) {
                @apply mt-1.5;
            }
        }
        &-icon {
            @apply w-8;
            &>svg {
                @apply min-h-8 min-w-8 fill-white;
            }
        }
    }
    &__close-button {
        @apply absolute top-2 right-2 opacity-100; 
        &>svg {
            @apply min-h-4 min-w-4 fill-white; 
        }
    }
    &__progress-bar {

    }
}

/* MESSAGES & NOTES */
.msg {
    @apply text-sm ring-1 ring-inset ring-brand_grey py-2 px-3 rounded-md bg-brand_faint-blue leading-tight font-medium items-center inline-flex gap-2 text-brand_grey-dark;
    &::before {
        content: '';
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 192 512"><path fill="%23006edb" d="M48 80a48 48 0 1 1 96 0A48 48 0 1 1 48 80zM0 224c0-17.7 14.3-32 32-32l64 0c17.7 0 32 14.3 32 32l0 224 32 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 512c-17.7 0-32-14.3-32-32s14.3-32 32-32l32 0 0-192-32 0c-17.7 0-32-14.3-32-32z"/></svg>');
        background-position: 12px 6px;
        @apply bg-no-repeat rounded-full h-[31px] min-w-[31px] max-w-[31px] ring-inset ring-2 ring-brand_status-info;
    }
    &-success {
        @apply text-brand_status-success;
        &::before {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 448 512"><path fill="%2322c55e" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>');
            background-position: 6px 6px;
            @apply ring-brand_status-success;
        }
    }
    &-warning {
        @apply text-brand_status-warning;
        &::before {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 128 512"><path fill="%23ef4444" d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>');
            background-position: 13px 6px;
            @apply ring-brand_status-warning;
        }
    }
    &-error {
        @apply text-brand_status-error;
        &::before {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 384 512"><path fill="%23ef4444" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>');
            background-position: 8px 6px;
            @apply ring-brand_status-error;
        }
    }
}

/* PROFILE COMPLETE DIAL */
@keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
}

/* REACT TOOLTIP */
.tooltip {
    @apply text-xs py-1.5 px-2.5 bg-brand_secondary-dark;
}